<template>
  <v-container>
    <div class="faq"> 
       <v-text-field
            solo
            prepend-inner-icon="mdi-magnify"
            label="Pergunta Frequentes"
            v-model="pesquisa" 
            @keyup="pesquisar"
            hide-details=""
            style="border-radius: 1em !important;"
        ></v-text-field>
    </div>
    <div v-for="categoria in Object.keys(faq)" :key="categoria">
        <div class="faq-categoria">
            <p @click="()=>toggleCat(categoria)">{{categoria}}</p>
            <div v-if="currcategoria === categoria">
                <div v-for="perg in faq[categoria]" :key="perg.id" class="faq">
                    <div>
                        <p class="faq-pergunta" @click="()=>toggleFaq(perg.id)">{{perg.pergunta}}</p>
                    </div>
                    <div v-if="currfaq == perg.id">
                        <p v-html="perg.resposta"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>    
  </v-container>
  
</template>


<script>
import axios from 'axios'

import { tokenapies } from '@/constantes.js'

export default {
  data: () => ({
    faq: [],
    currfaq: null,
    currcategoria: null,
    pesquisa: null,    
  }),
  methods: {
    carregarFaq() {
      let baseURL = (process.env.NODE_ENV == 'development') ? 'http://localhost:3343' : 'https://apies.economysoftware.com.br'
      axios.post(
        baseURL+"/faq/pergunta/endpoint/select", 
        {idsistema: 1},
        {headers:{'x-access-token':tokenapies}})
        .then((response) => {
          if (response.status == 200) {
            this.faq = this.groupByKey(response.data, 'nomecategoria')

          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    groupByKey (list, key) {
      return list.reduce((hash, obj) => ({...hash, [obj[key]]:( hash[obj[key]] || [] ).concat(obj)}), {})
    },
    toggleFaq(id){
      if(this.currfaq == id){
        this.currfaq = null
      }else{
        this.currfaq = id
      }
    },
    toggleCat(id){
      if(this.currcategoria == id){
        this.currcategoria = null
      }else{
        this.currcategoria = id
      }
    },
    pesquisar(){
      let baseURL = (process.env.NODE_ENV == 'development') ? 'http://localhost:3343' : 'https://apies.economysoftware.com.br'
      axios.post(
        baseURL+"/faq/pergunta/endpoint/select", 
        {pergunta: this.pesquisa},
        {headers:{'x-access-token':tokenapies}})
        .then((response) => {
          if (response.status == 200) {
            this.faq = this.groupByKey(response.data, 'nomecategoria')

          }
        })
        .catch((error) => {
          console.log(error);
        });
      
    }
  },
  async mounted() {
    await this.carregarFaq();
  },
};
</script>


<style lang="scss" scoped>
.faq-pergunta{
  color: #333;
  padding: .5em 1em;
  font-size: 1em;
  border-radius: 1em;
  letter-spacing: 0.02em;
  font-weight: 600;
  &:hover{
    cursor: pointer;
  }
  user-select: none;

}
.faq-resposta{
  background: #f9f9f2;
  padding: 1em;
  font-size: 1em !important;
  border-radius: 1em;
  letter-spacing: 0.02em;  
  color: #333;
  user-select: none;

}
.faq{
  padding: 1em;
  box-shadow:  0px 10px 20px rgba(128,128,128,.25);
  border-radius: 1em;
  margin: 1em 0;
  transition: max-height .25s ease-in;
  user-select: none;

}

.faq-categoria{
  padding: 1em;
  box-shadow:  0px 10px 20px rgba(128,128,128,.25);
  border-radius: 1em;
  margin: 1em 0;
  >p{
    color: #1377f2;
    text-transform: capitalize;
    font-size: 1.2em;
    font-weight: bold;
  }
  &:hover{
    cursor: pointer;
  }
  user-select: none;
}


.v-application p {
  margin-bottom: unset;
}
</style>