<template>
    <VerFaq />
</template>

<script>
import VerFaq from '@/components/Faq/VerFaq'
export default {
  components: {VerFaq},
  data(){
    return{}
  }
}
</script>

<style>

</style>